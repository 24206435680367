(function() {
    'use strict';
    angular
        .module('schulausflugApp')
        .factory('RandomCompany', RandomCompany);

    RandomCompany.$inject = ['$resource'];

    function RandomCompany ($resource) {
        var resourceUrl =  'api/randomCompany';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
