(function() {
    'use strict';
    angular
        .module('schulausflugApp')
        .factory('Newsletter', Newsletter);

    Newsletter.$inject = ['$resource'];

    function Newsletter ($resource) {
        var resourceUrl =  'api/newsletter';

        return $resource(resourceUrl, {}, {
        });
    }
})();
