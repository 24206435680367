(function() {
    'use strict';
    angular
        .module('schulausflugApp')
        .factory('FilteredNews', FilteredNews);

    FilteredNews.$inject = ['$resource', 'DateUtils'];

    function FilteredNews ($resource, DateUtils) {
        var resourceUrl =  'api/filteredNews';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
        });
    }
})();
