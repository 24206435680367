(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .controller('ContractDetailController', ContractDetailController);

    ContractDetailController.$inject = ['$http','$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Contract', 'Invoice', 'Company', 'InvoicesPerContract'];

    function ContractDetailController($http, $scope, $rootScope, $stateParams, previousState, DataUtils, entity, Contract, Invoice, Company, InvoicesPerContract) {
        var vm = this;

        vm.contract = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        vm.contract.invoices = InvoicesPerContract.get({id : vm.contract.id});

        vm.downloadPDF = downloadPDF;

        var unsubscribe = $rootScope.$on('schulausflugApp:contractUpdate', function(event, result) {
            vm.contract = result;
        });
        $scope.$on('$destroy', unsubscribe);


        function downloadPDF (id) {
            $http.get('api/invoices/'+id+'/pdf', {responseType: 'arraybuffer'}).then(function (response) {
                var blob = new Blob([response.data], {
                    type: "application/pdf"
                });

                var filename = "Rechnung_"+id+".pdf";
                saveAs(blob, filename);
            }, function (error) {
            //TODO: report error
            })
        }
    }

})();
