(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .controller('UserCompanyResultController', UserCompanyResultController);

    UserCompanyResultController.$inject = ['$scope', '$state', '$stateParams','UserCompany'];

    function UserCompanyResultController ($scope, $state, $stateParams, UserCompany) {
        var vm = this;
        $scope.mapCenters = {};
        $scope.mapMarkers = {};
        $scope.mapZoom = 6;
        $scope.busy = false;
        var randomCompany = $stateParams.company;
        var search = $stateParams.search;
        vm.companies = [];
        if($stateParams.company!=null) {
            $scope.mapCenters[randomCompany.id] = { latitude: randomCompany.latitude, longitude: randomCompany.longitude};
            $scope.mapMarkers[randomCompany.id] = [{ id: Math.random(), coords: { latitude: randomCompany.latitude, longitude: randomCompany.longitude}}]
            vm.companies= [randomCompany];
        } else if(search!=null) {
            $scope.busy = true;
            UserCompany.query({search: search}, function(result){
                result.forEach(function(c) {
                    if(vm.companies==null) vm.companies=[];
                    $scope.mapCenters[c.id] = { latitude: c.latitude, longitude: c.longitude};
                    $scope.mapMarkers[c.id] = [{ id: Math.random(), coords: { latitude: c.latitude, longitude: c.longitude}}]
                    vm.companies.push(c);
                });
                $scope.busy = false;
            });
        } else {
            $state.go('home');
        }
    }
})();
