(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .controller('InvoiceEmailController',InvoiceEmailController);

    InvoiceEmailController.$inject = ['$http','$uibModalInstance', 'entity'];

    function InvoiceEmailController($http,$uibModalInstance, entity) {
        var vm = this;

        vm.invoice = entity;
        vm.clear = clear;
        vm.sendPDFEmail = sendPDFEmail;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function sendPDFEmail (id) {
            $http.post('api/invoices/'+id+'/email').then(function () {
                $uibModalInstance.close(true);
            }, function (error) {
            //TODO: report error
            })
        }
    }
})();
