(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .controller('UserCompanyController', UserCompanyController);

    UserCompanyController.$inject = ['$scope', '$stateParams', 'Principal', 'LoginService', '$state', 'State', 'CompaniesPerState','UserCompany', 'companies'];

    function UserCompanyController ($scope, $stateParams, Principal, LoginService, $state, State, CompaniesPerState, UserCompany, companies) {
        var vm = this;
        $scope.mapCenters = {};
        $scope.mapMarkers = {};
        $scope.mapZoom = 6;
        // $scope.mapOptions = {disableDefaultUI: true};
        $scope.busy = false;

        vm.loadNextCompaniesPage = loadNextCompaniesPage;
        vm.loadNextAdjacentCompaniesPage = loadNextAdjacentCompaniesPage;
        vm.getStateName = getStateName;
        vm.type = $stateParams.type;
        vm.state= $stateParams.bl;
        vm.stateName = null;
        vm.subCategory = $stateParams.sk;
        vm.states = [];
        vm.subCategories = ['ALLWEATHERS', 'OUTDOOR'/*, 'WINTER'*/];
        vm.companies = null;
        vm.adjacent_companies = null;
        vm.itemsPerPage = 10;
        vm.page = -1;
        vm.adjacentPage = -1;
        vm.hasNextPage = true;
        vm.hasNextAdjacentPage = true;
        loadAllStates();

        if(vm.state) {
            loadNextCompaniesPage();
        }

        function loadAllStates() {
            State.query({companyCategory: vm.type, adjacentCompanies: false, images: (vm.state==null)}, function(result) {
                vm.states = result;
                vm.searchQuery = null;
            });
        }

        function getStateName() {
            for(var i=0, len=vm.states.length; i < len; i++){
                if(vm.states[i].id==vm.state) return vm.states[i].name
            }
        }

        function loadNextCompaniesPage() {
            if ($scope.busy) return;
            $scope.busy = true;
            vm.page = vm.page+1;
            if(vm.companies==null) vm.companies = [];
            CompaniesPerState.query({id: vm.state, category: vm.type, subCategory: vm.subCategory, page: vm.page, size: vm.itemsPerPage},  function(result) {
                vm.hasNextPage = result.length > 0;
                result.forEach(function(c) {
                    $scope.mapCenters[c.id] = { latitude: c.latitude, longitude: c.longitude};
                    $scope.mapMarkers[c.id] = [{ id: Math.random(), coords: { latitude: c.latitude, longitude: c.longitude}}]
                    vm.companies.push(c);
                });
                $scope.busy = false;

                if(!vm.hasNextPage) {
                    loadNextAdjacentCompaniesPage();
                }
            });

        }

        function loadNextAdjacentCompaniesPage() {
            if ($scope.busy) return;
            $scope.busy = true;
            vm.adjacentPage = vm.adjacentPage+1;
            if(vm.adjacent_companies==null) vm.adjacent_companies = [];
            CompaniesPerState.query({id: vm.state, adjacent: true, category: vm.type, subCategory: vm.subCategory, page: vm.adjacentPage, size: vm.itemsPerPage},  function(result) {
                vm.hasNextAdjacentPage = result.length > 0;
                result.forEach(function(c) {
                    $scope.mapCenters[c.id] = { latitude: c.latitude, longitude: c.longitude};
                    $scope.mapMarkers[c.id] = [{ id: Math.random(), coords: { latitude: c.latitude, longitude: c.longitude}}];
                    vm.adjacent_companies.push(c);
                });
                $scope.busy = false;
            });
        }
    }
})();
