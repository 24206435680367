(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('daytrip', {
            parent: 'app',
            url: '/tagesausfluege?bl&sk',
            data: {
                authorities: []
            },
            params: {type: 'DAYTRIP'},
            views: {
                'content@': {
                    templateUrl: 'app/user-company/user-companies.html',
                    controller: 'UserCompanyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                companies: ['UserCompany', function(UserCompany) {
                    return UserCompany.query();
                }]
            }
        })
        .state('accommodation', {
            parent: 'app',
            url: '/projektwochen?bl&sk',
            data: {
                authorities: []
            },
            params: {type: 'ACCOMMODATION'},
            views: {
                'content@': {
                    templateUrl: 'app/user-company/user-companies.html',
                    controller: 'UserCompanyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                companies: ['UserCompany', function(UserCompany) {
                    return UserCompany.query();
                }]
            }
        })
        .state('buscompany', {
            parent: 'app',
            url: '/busunternehmen?bl&sk',
            data: {
                authorities: []
            },
            params: {type: 'BUSCOMPANY'},
            views: {
                'content@': {
                    templateUrl: 'app/user-company/user-companies.html',
                    controller: 'UserCompanyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                companies: ['UserCompany', function(UserCompany) {
                    return UserCompany.query();
                }]
            }
        })
        .state('search', {
            parent: 'app',
            url: '/unternehmen',
            data: {
                authorities: []
            },
            params: {
                search: null,
                company: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-company/user-company-result.html',
                    controller: 'UserCompanyResultController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
