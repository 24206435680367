(function() {
    'use strict';

    angular
        .module('schulausflugApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'uiGmapgoogle-maps',
            'infinite-scroll'
        ])
        .filter('checkmark', function() {
            return function getCheckmark(input) {
                return input ? '\u2713' : '\u2718';
            }})
        .filter('category', function() {
            return function getCategory(input) {
                switch(input) {
                    case 'DAYTRIP':
                        return 'Tagesausflug';
                    case 'ACCOMMODATION':
                        return 'Projekttag/Projektwoche';
                    case 'BUSCOMPANY':
                        return 'Busunternehmen';
                    default:
                        return 'Unbekannt';
                }
            }})
        .filter('categoryPlural', function() {
            return function getCategory(input) {
                switch(input) {
                    case 'DAYTRIP':
                        return 'Tagesausflüge';
                    case 'ACCOMMODATION':
                        return 'Projekttage/Projektwochen';
                    case 'BUSCOMPANY':
                        return 'Busunternehmen';
                    default:
                        return 'Unbekannt';
                }
            }})
        .filter('subcategory', function() {
            return function getSubcategory(input) {
                switch(input) {
                    case 'ALLWEATHERS':
                        return 'Bei jedem Wetter';
                    case 'OUTDOOR':
                        return 'Outdoor';
                    case 'WINTER':
                        return 'Winter';
                    default:
                        return 'Unbekannt';
                }
            }})
        .filter('gender', function() {
            return function getTypeString(input) {
                return input=='MALE' ? 'Männlich' : 'Weiblich';
            }})
        .run(run);

    run.$inject = ['stateHandler'];

    function run(stateHandler) {
        stateHandler.initialize();
    }
})();
