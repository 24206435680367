(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('inquiry', {
            parent: 'app',
            url: '/inquiry/{id}',
            data: {
                authorities: []
            },
            params: {
                company: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/inquiry/inquiry.html',
                    controller: 'InquiryController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('inquiry-success', {
            parent: 'app',
            url: '/inquiry-success',
            data: {
                authorities: [],
                pageTitle: 'Anfrage erfolgreich'
            },
            views: {
                'content@': {
                    templateUrl: 'app/inquiry/inquiry-success.html'
                }
            }
        })
    }
})();
