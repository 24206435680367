(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .controller('CompanyDetailController', CompanyDetailController);

    CompanyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'states', 'Company', 'Contract', 'State', 'ContractsPerCompany'];

    function CompanyDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, states, Company, Contract, State, ContractsPerCompany) {
        var vm = this;

        vm.company = entity;
        vm.states = states;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.stateNameById = stateNameById;
        vm.stateIdNameMapping = {};
        mapNamesToIds();

        vm.company.contracts = ContractsPerCompany.get({id : vm.company.id});

        function stateNameById(id) {
            return vm.stateIdNameMapping[id];
        }

        function mapNamesToIds() {
            vm.states.forEach(function(state) {
                vm.stateIdNameMapping[state.id] = state.name;
            });
        }

        var unsubscribe = $rootScope.$on('schulausflugApp:companyUpdate', function(event, result) {
            vm.company = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
