(function() {
    'use strict';
    angular
        .module('schulausflugApp')
        .factory('UserCompany', UserCompany);

    UserCompany.$inject = ['$resource'];

    function UserCompany ($resource) {
        var resourceUrl =  'api/companies';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
