(function() {
    'use strict';
    angular
        .module('schulausflugApp')
        .factory('Inquiry', Inquiry);

    Inquiry.$inject = ['$resource'];

    function Inquiry ($resource) {
        var resourceUrl =  'api/inquiry';

        return $resource(resourceUrl, {}, {
        });
    }
})();
