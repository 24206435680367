(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .controller('InvoiceDetailController', InvoiceDetailController);

    InvoiceDetailController.$inject = ['$http', '$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Invoice', 'Contract'];

    function InvoiceDetailController($http, $scope, $rootScope, $stateParams, previousState, DataUtils, entity, Invoice, Contract) {
        var vm = this;

        vm.invoice = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.downloadPDF = downloadPDF;

        var unsubscribe = $rootScope.$on('schulausflugApp:invoiceUpdate', function(event, result) {
            vm.invoice = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function downloadPDF (id) {
            $http.get('api/invoices/'+id+'/pdf', {responseType: 'arraybuffer'}).then(function (response) {
                var blob = new Blob([response.data], {
                    type: "application/pdf"
                });

                var filename = "Rechnung_"+id+".pdf";
                saveAs(blob, filename);
            }, function (error) {
                //TODO: report error
            })
        }
    }
})();
