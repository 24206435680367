(function() {
    'use strict';

    angular
        .module('schulausflugApp')
        .controller('InquiryController', InquiryController);

    InquiryController.$inject = ['$scope', '$stateParams', '$state', 'DataUtils', 'Inquiry', 'Company'];

    function InquiryController ($scope, $stateParams, $state, DataUtils, Inquiry, Company) {
        var vm = this;

        vm.inquiry = {};
        vm.save = save;
        vm.error = null;
        vm.tos = false;
        vm.company = $stateParams.company;

        if($stateParams.id==null) {
            $state.go("home");
        } else {
            vm.inquiry.companyId = $stateParams.id;
        }

        if(vm.company==null) {
            vm.company = Company.get({id: $stateParams.id}, onGetSuccess, onGetError)
        }

        function onGetSuccess (result) {
            result
        }

        function onGetError() {
            $state.go("home");
        }

        function save() {
            vm.isSaving = true;
            Inquiry.save(vm.inquiry, onSaveSuccess, onSaveError)
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go("inquiry-success");
            vm.error = null;
        }

        function onSaveError () {
            vm.isSaving = false;
            vm.error = true;
        }
    }
})();
